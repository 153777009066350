class AxcoAnalytics {
  dataLayer = (args) => {
    const { dataLayer, analyticsParameters, appInsights } = window;
    if (dataLayer && analyticsParameters) {
      dataLayer.push({
        ...args,
        userID: analyticsParameters.userID,
        companyName: analyticsParameters.companyName,
        portalId: analyticsParameters.portalId,
      });
    }
    if (appInsights && analyticsParameters) {
      const { event, ...properties } = args;
      appInsights.trackEvent({
        name: event,
        properties: {
          ...properties,
          product: analyticsParameters?.product,
          userName: analyticsParameters?.username,
          userId: analyticsParameters?.userID,
          companyName: analyticsParameters?.companyName,
        },
      });
    }
  };
}

const AxcoAnalyticsTracker = new AxcoAnalytics();

export default AxcoAnalyticsTracker;
